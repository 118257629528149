import Typography from '@bluheadless/ui/src/atoms/typography/typography'
import Banner from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/banner/banner'

const BannerCtas = ({ ctas: inCtas, ...props }) => {
	// force variant to be 'outlined' for all ctas
	const ctas = inCtas.map((cta) => {
		// split label on <br /> and wrap each part in a span
		if (cta.text) {
			try {
				const parts = cta.text.split(/<br \/>|<br\/>/g)
				if (parts.length === 1) return { ...cta, variant: 'outlined' }
				cta.text = (
					<Typography component="h3" variant="subtitle1">
						{parts.map((part, index) => (
							<p key={index}>{part}</p>
						))}
					</Typography>
				)
			} catch (e) {
				// do nothing
			}
		}

		return { ...cta, variant: 'outlined' }
	})

	return <Banner {...props} ctas={ctas} />
}

export default BannerCtas
