import Typography from '../../atoms/typography'
import BHLink from '../../molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo'].includes(prop)
}

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	/* padding: 24px; */
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0;
	}
`

const Title = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.white};
	font-size: ${({ theme }) => theme.typography.pxToRem(40)};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(90)};
	}
`
const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	color: ${({ theme }) => theme.palette.common.white};
	margin-bottom: 22px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		margin-bottom: 32px;
	}
`
const Description = styled('div')`
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	color: ${({ theme }) => theme.palette.common.white};
	margin-top: 32px;
	line-height: 22px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		margin-top: 48px;
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings }) => (hasSiblings ? '40px' : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
		margin-top: ${({ hasSiblings }) => (hasSiblings ? '68px' : '0')};
	}
	.MuiButton-root {
		padding: 0;
	}
	.MuiTypography-subtitle1 {
		min-width: 225px;
		display: flex;
		flex-direction: column;
		${({ theme }) => theme.breakpoints.up('md')} {
			width: 100%;
			padding: ${({ theme }) => theme.spacing(2.5, 5)};
		}
		p {
			text-transform: uppercase;
			display: block;
			font-size: ${({ theme }) => theme.typography.pxToRem(24)};
			line-height: ${({ theme }) => theme.typography.pxToRem(27)};
			margin: 0;
			&:first-of-type {
				font-size: ${({ theme }) => theme.typography.pxToRem(14)};
				line-height: ${({ theme }) => theme.typography.pxToRem(16)};
				letter-spacing: 5.6px;
				margin-right: -5.6px; // fix empty space after last letter
			}
		}
	}
	${({ theme }) => theme.breakpoints.down('sm')} {
		> div {
			width: 100%;
		}
	}
`

const Link = styled(BHLink)`
	width: 100%;
`

const Button = styled(BHButton)``

export { Container, Title, Subtitle, Description, CtaContainer, Link, Button }
