import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'
import { CtaContainer, Subtitle } from '@bluheadless/ui/src/organisms/hero/hero-banner.styled'

const shouldForwardProp = (prop) =>
	![
		'autoplayAlways',
		'hide',
		'horizontalAlign',
		'light',
		'originalHeight',
		'originalWidth',
		'playIconHorizontalAlign',
		'playIconVerticalAlign',
		'playing',
		'startOnEveryInView',
		'stopOnEveryOutView',
		'template',
		'thresholdInView',
		'verticalAlign',
		'additionalContent',
		'subtitleProps',
		'hasVideo',
		'aboveTheFold',
	].includes(prop)

const textAlign = (align) => {
	switch (align) {
		case 'flex-start':
			return 'left'
		case 'flex-end':
			return 'right'
		case 'center':
			return 'center'
	}
}

const OverlayContainer = styled('div')`
	position: absolute;
	z-index: 2;
	left: 0;
	right: 0;
	max-width: 100%;
	margin: 41px 24px;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin: 100px 140px;
	}
`
const Root = styled(Grid, { shouldForwardProp })`
	align-items: ${({ verticalAlign }) => verticalAlign};
	justify-content: ${({ horizontalAlign }) => horizontalAlign};
	position: relative;
	text-shadow: ${({ theme }) => theme.textShadow};
	${OverlayContainer} {
		text-align: ${({ horizontalAlign }) => textAlign(horizontalAlign)};
	}
	&.ctas-alternative {
		${CtaContainer} {
			gap: 20px;
			a {
				padding: 8px 6px;
				font-size: ${({ theme }) => theme.typography.pxToRem(20)};
				${({ theme }) => theme.breakpoints.up('md')} {
					font-size: ${({ theme }) => theme.typography.pxToRem(24)};
				}
			}
			${({ theme }) => theme.breakpoints.up('md')} {
				gap: 80px;
			}
		}
		${Subtitle} {
			margin-bottom: 0;
			letter-spacing: 3.2px;
			font-size: ${({ theme }) => theme.typography.pxToRem(16)};
		}
	}
`

export { Root, OverlayContainer }
